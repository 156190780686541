/* Blog styling */

/* Blog post content styling */
.blog-content {
  color: rgba(255, 255, 255, 0.9);
  line-height: 1.7;
}

.blog-content h1,
.blog-content h2,
.blog-content h3,
.blog-content h4,
.blog-content h5,
.blog-content h6 {
  color: white;
  margin-top: 1.5em;
  margin-bottom: 0.75em;
  font-weight: 700;
}

.blog-content h1 {
  font-size: 2rem;
}

.blog-content h2 {
  font-size: 1.75rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding-bottom: 0.25rem;
}

.blog-content h3 {
  font-size: 1.5rem;
}

.blog-content h4 {
  font-size: 1.25rem;
}

.blog-content p {
  margin-bottom: 1.25em;
}

.blog-content a {
  color: #93c5fd;
  text-decoration: none;
  transition: color 0.15s ease;
}

.blog-content a:hover {
  color: #60a5fa;
  text-decoration: underline;
}

.blog-content ul,
.blog-content ol {
  padding-left: 1.5rem;
  margin-bottom: 1.25em;
}

.blog-content ul {
  list-style-type: disc;
}

.blog-content ol {
  list-style-type: decimal;
}

.blog-content li {
  margin-bottom: 0.5em;
}

.blog-content blockquote {
  border-left: 4px solid #4f46e5;
  padding-left: 1rem;
  color: rgba(255, 255, 255, 0.7);
  font-style: italic;
  margin: 1.5rem 0;
}

.blog-content pre {
  background-color: rgba(0, 0, 0, 0.3);
  padding: 1rem;
  border-radius: 0.375rem;
  overflow-x: auto;
  margin: 1.5rem 0;
}

.blog-content code {
  font-family: monospace;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 0.2rem 0.4rem;
  border-radius: 0.25rem;
  font-size: 0.875rem;
}

.blog-content pre code {
  background-color: transparent;
  padding: 0;
}

.blog-content img {
  max-width: 100%;
  height: auto;
  border-radius: 0.375rem;
  margin: 1.5rem 0;
}

.blog-content hr {
  border: 0;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  margin: 2rem 0;
}

.blog-content table {
  width: 100%;
  border-collapse: collapse;
  margin: 1.5rem 0;
}

.blog-content th {
  background-color: rgba(255, 255, 255, 0.1);
  font-weight: 600;
  text-align: left;
}

.blog-content th,
.blog-content td {
  padding: 0.75rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.blog-content tr:nth-child(even) {
  background-color: rgba(255, 255, 255, 0.05);
} 